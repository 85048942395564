export const fitnessRoutes = {
  top: () => `/fitness`,
  // user
  fitnessUserList: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
    `/fitness/${fitnessTenantID}/fitness-user`,
  fitnessUserNew: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
    `/fitness/${fitnessTenantID}/fitness-user-new`,
  fitnessUserDetail: ({
    fitnessTenantID,
    fitnessUserID,
  }: {
    fitnessTenantID: string;
    fitnessUserID: string;
  }) => `/fitness/${fitnessTenantID}/fitness-user/${fitnessUserID}`,
  // staff
  fitnessStaffList: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
    `/fitness/${fitnessTenantID}/fitness-staff`,
  fitnessStaffNew: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
    `/fitness/${fitnessTenantID}/fitness-staff-new`,

  // user
  userInitialPassword: () => `/user/initial-password`,

  // auth
  authLogin: () => `/auth/login`,
  authConfirm: () => `/auth/confirm`,
  authSendPasswordResetEmail: () => `/auth/send-reset-password-email`,
  authSendPasswordResetEmailSuccess: () =>
    `/auth/send-reset-password-email-success`,
};

export const fitnessActions = {
  deleteFitnessStaff: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
    `/fitness/${fitnessTenantID}/fitness-staff-delete`,
  reinviteFitnessStaff: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
    `/fitness/${fitnessTenantID}/fitness-staff-reinvite`,
  updateFitnessUser: ({
    fitnessTenantID,
    fitnessUserID,
  }: {
    fitnessTenantID: string;
    fitnessUserID: string;
  }) => `/fitness/${fitnessTenantID}/fitness-user/${fitnessUserID}/update`,
  deleteFitnessUser: ({
    fitnessTenantID,
    fitnessUserID,
  }: {
    fitnessTenantID: string;
    fitnessUserID: string;
  }) => `/fitness/${fitnessTenantID}/fitness-user/${fitnessUserID}/delete`,
  searchFitnessTenants: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
    `/fitness/${fitnessTenantID}/search-medical-tenants`,
  searchRelatedMedicalTenants: ({
    fitnessTenantID,
  }: {
    fitnessTenantID: string;
  }) => `/fitness/${fitnessTenantID}/search-related-medical-tenants`,
  upsertMedicalPrescription: ({
    fitnessTenantID,
    fitnessUserID,
  }: {
    fitnessTenantID: string;
    fitnessUserID: string;
  }) =>
    `/fitness/${fitnessTenantID}/fitness-user/${fitnessUserID}/upsert-medical-prescription`,

  // user
  userSendPasswordResetEmail: () => `/user/send-reset-password-email`,
  userLogout: () => `/user/logout`,

  // auth
  authSendPasswordResetEmail: () => `/auth/send-reset-password-email`,
};
